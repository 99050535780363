import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {


        //悬浮窗数据
        floatingWindow: {},

        /**
         * 悬浮窗设置项
         *    title 设置项标题
         *    type 设置项类型
         *    date 设置项数据
         *
         *
         *    type:[
         *        switch 开关
         *        text_size 调整大小
         *
         *    ]
         *
         */

        floatingWindowSetting: [
            {
                title: "悬浮窗数据是否缓存",
                type: "switch",
                date: false
            },
            {
                title: "JSON输入框内文字大小",
                type: "text_size",
                date: false
            },
            {
                title: "JSONPretty文字大小",
                type: "text_size",
                date: false
            },
            {
                title: "JAVA代码执行文字大小",
                type: "text_size",
                date: false
            },

        ],


        //悬浮窗初始数据
        floatingWindowInitial: {
            //悬浮窗是否缓存
            dataCache: false,
            //输入框文字大小
            fontSize: 16,
            //下方pretty文字大小
            prettySize: 16

        },


        jsonStr: '',
        extendBol: false,
        cols: [{
            span: 12,
            name: 'textarea',
            value: '',
            desc: 'json原数据',
        }, {
            span: 12,
            name: 'convertJsonStr',
            value: '',
            desc: 'jsonpath解析结果',
        }],

    },

})
