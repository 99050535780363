<template>
  <div>
    <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="10px" class="demo-dynamic">
      <el-form-item
          v-for="(domain, index) in dynamicValidateForm.domains"
          :key="domain.key"
          :prop="'domains.' + index + '.value'"
          :rules="{
      required: true, message: '字段映射不能为空', trigger: 'blur'
    }">
        <el-row :span="24">
          <el-col :span="10">
<!--            <el-input v-model="domain.keyStr" placeholder="原数据映射字段path" @change="domainInputChange(index)"></el-input>-->
            <el-autocomplete v-model="domain.keyStr" placeholder="原数据映射字段path"
                :fetch-suggestions="querySearchAsync" @select="handleSelect(index, true)"
                             @input="handleSelect(index, false)"/>
          </el-col>

          <el-col :span="10">
            <el-input v-model="domain.valueStr" placeholder="目标字段path" @change="domainInputChange(index)"></el-input>
          </el-col>

          <el-col :span="4">
            <el-button type="danger" icon="el-icon-delete" circle @click.prevent="removeDomain(domain)"></el-button>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('dynamicValidateForm')">提交</el-button>
        <el-button  type="primary" plain @click="addDomain">新增字段</el-button>
        <el-button  type="warning" plain @click="resetForm('dynamicValidateForm')">重置</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>
<script>
import {jsonMappingCheck, jsonMappingTransformation} from "@/api/jsonPathUtil";

export default {
  data() {
    return {

      files: [],

      timeout: null,

      response: {
        code: 0,
        msg: '',
        result: ''
      },
      searchResponse: {
        code: 0,
        msg: '',
        result: {
          fieldSearchList: []
        }
      },
      dynamicValidateForm: {
        domains: [{
          value: ''
        }],
        oldDomains: [{
          value: ''
        }],
        email: ''
      }
    };
  },

  mounted() {
    this.files = this.queryFields('');
  },

  methods: {
    domainInputChange(index) {
      let valueOf = this.dynamicValidateForm.domains[index].valueOf()
      if (valueOf.keyStr === undefined || valueOf.keyStr.trim().length === 0) {
        valueOf.value = '';
      } else {
        valueOf.value = valueOf.keyStr;
      }
      if (valueOf.valueStr === undefined || valueOf.valueStr.trim().length === 0) {
        valueOf.value = '';
      } else {
        valueOf.value += valueOf.valueStr;
      }
      this.dynamicValidateForm.domains[index] = valueOf;
    },

    submitForm(formName) {
      let originStr = this.$store.state.jsonStr;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          let mapping = this.dynamicValidateForm.domains.map(domain => {
            return {
              key: domain.keyStr,
              value: domain.valueStr
            }
          })
          let param = {
            fieldMappingOriginJson: originStr,
            filedMapping: mapping,
            noResultIgnore: true
          }
          jsonMappingTransformation(param).then((response) => {
            this.response = response;
            let result = this.response.result.convertJsonStr;
            const parsedJson = JSON.parse(result)
            result = JSON.stringify(parsedJson, null, 2)
            this.$store.state.cols[this.$store.state.cols.length - 1].value = result;
          })
        } else {
          return false;
        }
      });
    },

    /**
     * 重置按钮
     */
    resetForm() {
      this.dynamicValidateForm.domains=[{
        value: '',
        key: Date.now(),
        keyStr: '',
        valueStr: ''
      }];
      this.$store.state.cols[this.$store.state.cols.length - 1].value = '';
    },

    removeDomain(item) {
      let index = this.dynamicValidateForm.domains.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1)
        this.dynamicValidateForm.oldDomains.splice(index, 1)
      }
      if (this.dynamicValidateForm.domains.length<1){
        let length = this.$store.state.cols.length - 1;
        this.$store.state.extendBol = false;
        this.$store.state.cols = this.$store.state.cols.filter(col => col.name !== 'filedMapping');
        let spanTemp = 24 / length;
        this.$store.state.cols = this.$store.state.cols.map(col => {
          col.span = spanTemp
          return col;
        });
      }
    },


    addDomain() {
      this.dynamicValidateForm.domains.push({
        value: '',
        key: Date.now(),
        keyStr: '',
        valueStr: ''
      });
      this.dynamicValidateForm.oldDomains.push({
        keyStr: '',
      });
      let fields = this.queryFields('');
    },

    querySearchAsync(queryString, cb) {
      let files = this.files;
      if (files === undefined) {
        return;
      }
      let results = queryString ? files.filter(this.createStateFilter(queryString)) : files;
      cb(results);
      if (files.size === 0) {
        let fields = this.queryFields(queryString);
      }
    },
    createStateFilter(queryString) {
      return (state) => {
        if (queryString.indexOf('.') !== -1) {
          queryString = queryString.substring(queryString.lastIndexOf(".") + 1);
        }
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
      };
    },

    handleSelect(index, search) {
      let keyStr = this.dynamicValidateForm.domains[index].keyStr;
      let valueOf = this.dynamicValidateForm.domains[index].valueOf()
      keyStr = valueOf.keyStr;
      if (keyStr.endsWith(".")) {
      }
      if (!keyStr.endsWith(".") && !search && keyStr !== '') {
        return;
      }
      if (keyStr === '') {
        this.dynamicValidateForm.domains[index].keyStr = keyStr;
      }
      if (search) {
        let oldKeyStr = this.dynamicValidateForm.oldDomains[index].keyStr;
        if (oldKeyStr !== undefined && oldKeyStr !== '') {
          this.dynamicValidateForm.domains[index].keyStr = oldKeyStr +  keyStr;
          this.dynamicValidateForm.oldDomains[index].keyStr = oldKeyStr + keyStr;
        }
        console.info("new keyStr", this.dynamicValidateForm.domains[index].keyStr)

      }
      let fields = this.queryFields(keyStr);
      this.dynamicValidateForm.oldDomains[index].keyStr = keyStr;
    },

    queryFields(keyStr) {
      let jsonStr = this.$store.state.jsonStr;
      if (jsonStr === '' || jsonStr === undefined) {
        return;
      }
      let param = {
        fieldSearchOriginJson: jsonStr,
        keyStr: keyStr
      }
      jsonMappingCheck(param).then((response) => {
        this.searchResponse = response;
        this.files = this.searchResponse.result.fieldSearchList;
      })
      return this.files;
    },
  }
}
</script>
<style scoped>
</style>
