import request from '@/utils/request'



//映射转换
export function mainClass(data) {
    return request({
        url: '/utilCollection/code/execute/mainClass',
        method: 'post',
        data:data
    })
}
