import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import jsonUtil from "@/views/admin/jsonUtil";
import dateUtil from "@/views/dateUtil/dateUtil";
import jsonPath from "@/views/jsonUtil/jsonPath";
import jsonMapping from "@/views/jsonUtil/jsonMapping";
import mainExecute from "@/views/mainExecute/mainExecute";
import requestTest from "@/views/admin/requestTest";
import jsonFormatting from "@/views/jsonUtil/jsonFormatting";
import cronUtil from "@/views/cronUtil";

Vue.use(VueRouter)


const routes = [
  {
    path: '*',
    redirect: '/admin/jsonUtil' // 默认路由重定向到 /admin
  },

  {
    path: '/admin',
    name: 'admin',
    component: Home,
    children: [
      {path: 'jsonUtil', name: 'jsonUtil', component: jsonUtil,
          redirect: '/admin/jsonUtil/jsonFormatting',
          children: [
              {path: 'jsonFormatting', name: 'jsonFormatting', component: jsonFormatting,},
              {path: 'jsonPath', name: 'jsonPath', component: jsonPath,},
              {path: 'jsonMapping', name: 'jsonMapping', component: jsonMapping,},
          ]},
      {path: 'cronUtil', name: 'cronUtil', component: cronUtil,},
      {path: 'dateUtil', name: 'dateUtil', component: dateUtil,},
      {path: 'mainExecute', name: 'mainExecute', component: mainExecute},
      {path: 'requestTest', name: 'requestTest', component: requestTest,},

    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
