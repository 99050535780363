<template>


<div>

  <el-row :span="24">
    <div style="text-align: right;height: 60px;padding-right: 20px">

    </div>
  </el-row>


  <el-row style="height: 500px">

    <el-col :span="12">

      <div style="height: 60px;background-color: rgba(0,0,0,0.05);border-radius: 20px 20px 0 0;
 display: flex;
  align-items: center; /* 垂直居中 */
">
        <div style="margin-left: 20px;font-size: 18px">

            源代码:

        </div>
        <div style="margin-right: 20px;margin-left: auto;">
          <el-button style="width: 150px;" type="success" @click="runJavaCode()">运行 <i class="el-icon-video-play"></i></el-button>
        </div>

      </div>
        <codemirror ref="cm"  class="cm" v-model="code" :options="options"></codemirror>

    </el-col>


    <el-col :span="11" style="margin-left: 2px">
      <div style="height: 60px;background-color: rgba(0,0,0,0.05);border-radius: 20px 20px 0 0;
 display: flex;
  align-items: center; /* 垂直居中 */
">
        <div style="margin-left: 20px;font-size: 18px">结果:</div>

      </div>
      <codemirror ref="rm"  class="cm" v-model="outputText" :options="options"></codemirror>
<!--      <el-input-->
<!--          type="textarea"-->
<!--          style="padding-left: 10px;font-size: 18px;"-->
<!--          rows="15"-->
<!--          placeholder="结果"-->
<!--          v-model="outputText">-->
<!--      </el-input>-->
    </el-col>
  </el-row>

<!--  <el-row>-->
<!--    <el-col :span="2">-->
<!--      代码折叠:-->
<!--      <el-switch-->
<!--          v-model="options.foldGutter"-->
<!--          active-color="#13ce66"-->
<!--          inactive-color="#ff4949">-->
<!--      </el-switch>-->
<!--    </el-col>-->
<!--    <el-col :span="2">-->
<!--      只读:-->
<!--      <el-switch-->
<!--          v-model="options.readOnly"-->
<!--          active-color="#13ce66"-->
<!--          inactive-color="#ff4949">-->
<!--      </el-switch>-->
<!--    </el-col>-->
<!--  </el-row>-->




</div>
</template>
<script>

import {codemirror} from 'vue-codemirror';


import '@/utils/cm-setting.js'
import {mainClass} from "@/api/javaCode";



export default {
  components: {
  codemirror
  },

  data() {
    return{
      outputText:"",
      code: "public class Test {\n" +
          "\tpublic static void main(String[] args){\n" +
          "\t\tSystem.out.println(\"hello luvian\");\n" +
          "\t}\n" +
          "}",
      options: {
        line: true,
        // theme: "rubyblue", // 主题
        theme: "idea", // 主题
        tabSize: 4, // 制表符的宽度
        indentUnit: 2, // 一个块应该缩进多少个空格（无论这在编辑语言中意味着什么）。默认值为 2。
        firstLineNumber: 1, // 从哪个数字开始计算行数。默认值为 1。
        readOnly: false, // 只读
        autorefresh: true,
        smartIndent: true, // 上下文缩进
        lineNumbers: true, // 是否显示行号
        styleActiveLine: true, // 高亮选中行
        viewportMargin: Infinity, //处理高度自适应时搭配使用
        showCursorWhenSelecting: true, // 当选择处于活动状态时是否应绘制游标
        matchBrackets: true, // 括号匹配显示
        autofocus: true,
        autoRefresh: true,
        extraKeys:{'Ctrl':'autocomplete' },
        foldGutter: true,//代码折叠
        mode: "text/x-java",
        gutters: [
          "CodeMirror-linenumbers",
          "CodeMirror-foldgutter",
          "CodeMirror-lint-markers",      // CodeMirror-lint-markers是实现语法报错功能
        ],
        hintOptions:{
          completeSingle: false,
          tables: {
            users: ['name', 'score ', 'birthDate'],
            countries: ['name', 'population', 'size']
          }
        }




      },

    }

  },
  mounted() {

  },
  methods: {
    runJavaCode(){
      mainClass({mainClassStr:this.code}).then((response) => {
        this.outputText=response.result.consoleStr;
      })

    },



  }
};
</script>
<style>

    .cm{
      text-align: left;
      font-size: 18px;
      width: auto;
    }

    .CodeMirror{
      height:800px !important;
    }

</style>
