import request from '@/utils/request'


//jsonPath基础解析
export function jsonEvaluation(data) {
    return request({
        url: '/utilCollection/jsonPath/evaluation',
        method: 'post',
        data:data
    })
}

//映射转换
export function jsonMappingTransformation(data) {
    return request({
        url: '/utilCollection/field/mapping/convert',
        method: 'post',
        data:data
    })
}


//映射检查
export function jsonMappingCheck(data) {
    return request({
        url: '/utilCollection/field/mapping/search',
        method: 'post',
        data:data
    })
}


//main方法执行
export function mainExecute(data) {
    return request({
        url: '/utilCollection/code/execute/mainClass',
        method: 'post',
        data:data
    })
}