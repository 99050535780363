<template>

  <div>
      <el-input
          v-model="cronText"
          placeholder="cron执行表达式"
      >
        <template slot="append">
          <el-button type="primary" @click="openCronTure">
            查看表达式
          </el-button>
        </template>
      </el-input>
    <br><br><br>
      <crontab
          ref="crontab"
          :expression="expression"
          @hide="openCron"
          @fill="crontabFill" />

  </div>




</template>




<script>
import Crontab from "@/components/Crontab";


export default {

  components: {
    Crontab,
  },
  data(){
    return{
      // 传入的表达式
      expression: "",
      cronText:"",
      openCron:true,

    }
  },
  methods:{

    /**
     * cron执行表达式
     */
    openCronTure(){
      this.expression = this.cronText
      this.$refs.crontab.resolveExp()
    },

    /** 确定后回传值 */
    crontabFill(value) {
      this.cronText = value
    },
  }


}


</script>

<style scoped>

</style>

