<template>
  <div id="app">


    <el-container style="height: 100%;">


      <!-- 侧边栏菜单组件 -->
      <el-menu
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          :collapse="isCollapse"
          :default-active="defaultTab"
          :collapse-transition="false"
          :style="{width :isCollapse?'70px':'230px'}"
          open="1-1" >
        <!-- 侧边栏 -->


        <el-menu-item>
          <el-tooltip class="item" effect="dark" content="喵喵喵~" placement="right">
            <img style="width: 40px; height: 30px" src="../image/cat.png"/>
          </el-tooltip>
          <!--                <span slot="title"></span>-->
        </el-menu-item>




        <el-submenu  index="1">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>在线工具</span>
          </template>
          <el-submenu index="1-1">
            <template slot="title">json工具</template>
            <el-menu-item index="1-1-1" @click="handleClick('json处理', 'jsonUtil','1-1-1')">json处理</el-menu-item>
          </el-submenu>

          <el-menu-item-group>
            <el-menu-item index="2" @click="handleClick('时间戳', 'dateUtil','2')">时间戳</el-menu-item>
          </el-menu-item-group>

          <el-menu-item-group>
            <el-menu-item index="3" @click="handleClick('cron表达式', 'cronUtil','3')">cron表达式</el-menu-item>
          </el-menu-item-group>

          <el-menu-item-group>
            <el-menu-item index="4" @click="handleClick('java代码执行', 'mainExecute','4')">java代码执行</el-menu-item>
          </el-menu-item-group>

          <el-menu-item-group>
            <el-menu-item index="5" @click="handleClick('发送请求示例', 'requestTest','5')">发送请求示例</el-menu-item>
          </el-menu-item-group>


        </el-submenu>


        <!--              侧边栏开关-->
        <div class="sidebar-button">
          <img @click="isCollapseFun()"
               :src="!isCollapse?unfold:close"
               style="width: 40px;height: 60px;">
        </div>

      </el-menu>


      <el-main style="background-color: rgba(157,157,157,0.06);">


        <el-col>
          <el-row>
            <div style="width: 100%;height: 30px;text-align: left;margin-bottom: 15px;">

            </div>
          </el-row>
          <el-row >
<!--            tab页-->
            <el-tabs v-model="topTabsValue" type="card" @tab-click="handleClickTab" @tab-remove="removeTab">
              <el-tab-pane
                  v-for="(item, index) in topTabs"
                  :key="item.name"
                  :label="item.title"
                  :closable="!item.isClosable"
                  :name="item.name">
                {{ item.content }}
              </el-tab-pane>

<!--              内容区-->
                <router-view></router-view>



            </el-tabs>


          </el-row>

        </el-col>
      </el-main>


      <!--      &lt;!&ndash; 内容区 &ndash;&gt;-->
      <!--      <el-main>-->
      <!--          <Tag />-->
      <!--      </el-main>-->

      <!--    悬浮窗-->
      <FloatingWindow/>

    </el-container>
  </div>
</template>

<script>

// 导入组件
import FloatingWindow from "@/components/floatingWindow/index.vue";
import UNFOLD from "../image/icons/k.png"
import CLOSE from "../image/icons/g.png"


export default {
  name: "Home",
  components: {
    FloatingWindow
  },
  data() {
    return {

      //顶部tab页
      /**
       * 格式:
       * {
       *       title: 'json处理',
       *       name: '2',
       *     }
       */
      //顶部tab页展示的name
      topTabsValue: "1",
      //防止重复点击报错
      topTabsValueRep: "1",
      topTabs: [{
        index:"1-1-1",
        title: 'json处理',
        name: '1',
        isClosable: "closable",//不可关闭
        route: 'jsonUtil',
      }],

      //默认长度，根据有上面topTabs的数据量决定
      topIndex:1,

      //侧边栏开关
      isCollapse: false,
      unfold: UNFOLD,
      close: CLOSE,
      //默认选中tab页
      defaultTab:"1-1-1",



    };
  },
  created(){
     this.$router.push({name: this.topTabs[0].route}).catch(err => err);

  },
  methods: {

    //顶部点击
    handleClickTab(tab, event) {
      this.cutTabs(tab.index);
    },

    //切换tabs
    cutTabs(index){
        this.defaultTab = this.topTabs[index].index

      if (this.topTabsValueRep === this.topTabsValue){
        return
      }
      this.topTabsValueRep = this.topTabsValue;

      this.$router.push({name: this.topTabs[index].route});
    },

    //测边栏
    handleClick(title, route, index) {
      let tabTemp;
      let editableTabs = this.topTabs;
      let name;
      editableTabs.forEach((tab, index) => {
        if (tab.route === route) {
          tabTemp = tab
        }
      });

      if (tabTemp === undefined) {
        let newTabName = ++this.topIndex+'';
        tabTemp = {
          title: title,
          name: newTabName,
          route: route,
          index: index
        };
        this.topTabs.push(tabTemp);
      }
      name = tabTemp.name;
      this.defaultTab = index;
      this.topTabsValue = name;
      this.topTabsValueRep = name;
      this.$router.push({name: tabTemp.route}).catch(err => err)
    },
    //开关侧边栏
    isCollapseFun() {
      this.isCollapse = this.isCollapse ? false : true
    },
    //删除顶部tab页
    removeTab(targetName) {
      let tabs = this.topTabs;
      let activeName = this.topTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      //当前标签删掉就去上一个
      if (this.topTabsValue === targetName){
        this.$router.push({name: tabs.find(e => e.name === activeName).route});
      }
      this.topTabsValue = activeName;
      this.topTabsValueRep = activeName;
      this.topTabs = tabs.filter(tab => tab.name !== targetName);
    },
  },

};
</script>
<style lang="scss" scoped>

#app, html {
  height: 100%;
}

.el-main {
  background-color: #e9eef3;
  text-align: center;
}

//侧边栏开关
.sidebar-button {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 65px;
  background-color: rgba(255, 0, 0, 0);
}


</style>
