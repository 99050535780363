<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="21" >
              <el-autocomplete style="width: 100%" v-model="jsonPath" placeholder="请输入jsonPath"
                               :fetch-suggestions="querySearchAsync" @select="handleSelect(true)"
                               @input="handleSelect(false)"

              />
          </el-col>
          <el-col :span="3">
            <el-button type="primary" @click="extend">jsonPath扩展</el-button>
          </el-col>
        </el-row>
      </el-header>

      <el-main>
        <div>
          <el-row :span="24">
            <el-col v-for="(item, index) in this.$store.state.cols"
                    :span="item.span">
              <el-input v-if="item.name !== 'filedMapping'"
                        type="textarea"
                        :style="{fontSize: $store.state.floatingWindow.fontSize+'px'}"
                        :autosize="{ minRows: 27, maxRows: 14}"
                        :placeholder="item.desc"
                        tabindex="true"
                        @blur="textBlur()"
                        v-model="item.value" @input="changeJsonStr(index, item.value)">
              </el-input>

              <el-form v-if="item.name === 'filedMapping'" :model="dynamicValidateForm" ref="dynamicValidateForm"
                       label-width="10px" class="demo-dynamic">
                <jsonMapping />
              </el-form>
            </el-col>
          </el-row>


          <el-row>
            <el-col>
              <div>
                展示key双引号
                <el-switch
                    v-model="state.showDoubleQuotes"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value=true
                    :inactive-value=false >
                </el-switch>
                折叠时展示长度
                <el-switch
                    v-model="state.showLength"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value=true
                    :inactive-value=false
                >
                </el-switch>
                展示行计数
                <el-switch
                    v-model="state.showLineNumber"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value=true
                    :inactive-value=false >
                </el-switch>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div>
                <vue-json-pretty
                    :style="{fontSize: $store.state.floatingWindow.prettySize+'px'}"
                    :data="leftResData()"
                    :show-double-quotes="state.showDoubleQuotes"
                    :show-length="state.showLength"
                    :show-line="state.showLine"
                    :show-line-number="state.showLineNumber"
                    :collapsed-on-click-brackets="state.collapsedOnClickBrackets"
                    :show-select-controller="state.showSelectController"
                    :show-icon="state.showIcon"
                />
              </div>
            </el-col>
            <el-col :span="11">
              <div>
                <vue-json-pretty
                    :style="{fontSize: $store.state.floatingWindow.prettySize+'px'}"
                    :data="rightResData()"
                    :show-double-quotes="state.showDoubleQuotes"
                    :show-length="state.showLength"
                    :show-line="state.showLine"
                    :show-line-number="state.showLineNumber"
                    :collapsed-on-click-brackets="state.collapsedOnClickBrackets"
                    :show-select-controller="state.showSelectController"
                    :show-icon="state.showIcon"
                />
              </div>
            </el-col>
          </el-row>


        </div>
      </el-main>



    </el-container>
  </div>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty';
import jsonMapping from "@/views/jsonUtil/jsonMapping";
import {jsonEvaluation, jsonMappingCheck} from "@/api/jsonPathUtil";
export default {
  components: {
    jsonMapping,
    VueJsonPretty
  },
  data() {
    return {

      files: [],

      state:{
        //是否展示key双引号
        showDoubleQuotes:true,
        //折叠时展示长度
        showLength:true,
        //展示行计数
        showLineNumber:true,
        //支持点击括号折叠
        collapsedOnClickBrackets:true,
        //展示选择器
        showSelectController:true,
        //展示图标
        showIcon:true,
        //展示标识线
        showLine:true,
      },
      jsonPath: '',
      //结尾jsonpath
      endJsonPath: '',

      textarea: '',
      convertJsonStr: '',
      response: {
        code: 0,
        msg: '',
        result: ''
      },



      dynamicValidateForm: {
        domains: [{
          name: '',
          keyStr: '',
          valueStr: '',
          value: ''
        }],
        oldDomains: [{
          value: ''
        }],
        email: ''
      },


    };

  },
  mounted() {
     this.files = this.queryFields('');
      this.init()
  },
  methods: {
    leftResData(){
      if (this.textarea==null||this.textarea==""){
        return null;
      }
      let json
      try{
        json = JSON.parse(this.textarea);
      }catch (e){
        console.log(this.textarea,"可能不是json")
        return null
      }
      return json;
    },

    rightResData(){
      let text = this.$store.state.cols[this.$store.state.cols.length - 1].value;
      if (text==null|text==""){
        return null;
      }
      return JSON.parse(text);
    },


    //大文本框失去焦点
    textBlur(){
      if (this.jsonPath!=null&&this.textarea!=null){
        this.queryFields('');
      }
    },


    /**
     * jsonPath输入框处理
     * @param queryString
     * @param cb
     */


    querySearchAsync(queryString, cb) {//匹配
      let files = this.files;
      if (files === undefined) {
        return;
      }
      //匹配字段返回列表
      let results = queryString ? files.filter(this.createStateFilter(queryString)) : files;
      cb(results);

    },

    //根据输入的字段匹配
    createStateFilter(queryString) {
      return (state) => {
        if (queryString.indexOf('.') !== -1) {
          queryString = queryString.substring(queryString.lastIndexOf(".") + 1);
        }
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
      };

    },


    /**
     * 列表选择事件
     */
    handleSelect(search) {

      let keyStr = this.jsonPath;

      if (!keyStr){
        this.endJsonPath = keyStr
        this.jsonPath = keyStr
        this.queryFields('');
      }

      if (search){//点击选择时触发
        let oldKeyStr = this.endJsonPath
        if (oldKeyStr){
          this.endJsonPath = oldKeyStr.substring(0, oldKeyStr.lastIndexOf(".") + 1) + keyStr
          this.jsonPath = oldKeyStr.substring(0, oldKeyStr.lastIndexOf(".") + 1) + keyStr;
        }
        this.inputChange();
      }else if (this.jsonPath.indexOf(".") != -1){//任何操作都触发
          this.endJsonPath = this.jsonPath
      }

      if (keyStr === this.endJsonPath.substring(0,this.endJsonPath.indexOf("."))){
        this.endJsonPath = this.jsonPath
      }

      if (keyStr.indexOf(".") === -1){ //没有.返回最初层
        this.queryFields('');
        return;
      }

      if (keyStr.endsWith(".")){//最后有.
        this.queryFields(keyStr);
      }else{
        let lsstr = this.jsonPath.substring(0,this.jsonPath.lastIndexOf("."))   //查上一个
        this.queryFields(lsstr);
      }
      this.endJsonPath = keyStr

    },



    queryFields(keyStr) {
      //略过$.
      if (keyStr == "$."){
        keyStr = ""
      }
      if (keyStr.substring(0,2) == "$."||keyStr == "$"){
        keyStr = keyStr.substring(2, keyStr.length)
      }

      let jsonStr = this.$store.state.jsonStr;
      if (jsonStr === '' || jsonStr === undefined) {
        return;
      }
      let param = {
        fieldSearchOriginJson: jsonStr,
        keyStr: keyStr
      }
      jsonMappingCheck(param).then((response) => {
         this.files = response.result.fieldSearchList;
      })
      return this.files;
    },






    /**
     * jsonPath输入框处理结束
     * @param queryString
     * @param cb
     */




    init() {
      this.textarea = this.$store.state.jsonStr;
      this.$store.state.cols[0].value = this.textarea;
    },




    inputChange() {
      if ( this.jsonPath ==null){
        return;
      }

      let queryParams = {
        originObj: this.textarea,
        jsonPath: this.jsonPath,
      }

      jsonEvaluation( queryParams).then((response) => {
        this.response = response;
        let result = JSON.stringify(this.response.result);
        const parsedJson = JSON.parse(result)
        result = JSON.stringify(parsedJson, null, 2)
        this.$store.state.cols[this.$store.state.cols.length - 1].value = result;
      })
    },


    extend() {
      let length;
      if (this.$store.state.extendBol) {
        length = this.$store.state.cols.length - 1;

        this.$store.state.extendBol = false;

        this.$store.state.cols = this.$store.state.cols.filter(col => col.name !== 'filedMapping');

      } else {
        length = this.$store.state.cols.length + 1
        this.$store.state.extendBol = true;
        let col = this.$store.state.cols[1];
        this.$store.state.cols[1] = {
          name: 'filedMapping',
          desc: '映射字段关联'
        }
        this.$store.state.cols[2] = col;
      }

      let spanTemp = 24 / length;
      this.$store.state.cols = this.$store.state.cols.map(col => {
        col.span = spanTemp
        return col;
      });


    },
    changeJsonStr(index,inputStr) {
      this.textarea = inputStr;
      if (index === 0) {
        this.$store.state.jsonStr = inputStr;
      }
      this.$store.state.cols[index].value = inputStr;
    },




  }
};
</script>
<style scoped>
</style>
