<template>
<!--json格式化页面-->
  <div>
    <el-container>
      <el-header>

        <el-row>

          <el-button type="primary" @click="format">格式化</el-button>
          <el-button @click="compression">压缩</el-button>
          <el-button @click="escape">转义</el-button>
          <el-button @click="clearEscape">去除转义</el-button>
          <el-button @click="decodeUnicode">Unicode转中文</el-button>
          <el-button @click="encodeUnicode">中文转Unicode</el-button>
          <el-button @click="urlEncoder">UrlEncoder</el-button>
          <el-button @click="urlDecoder">UrlDecoder</el-button>
          <el-button type="danger" icon="el-icon-delete" @click="clear"></el-button>

        </el-row>

      </el-header>
      <el-main>
        <span style="color: red" v-if="isException">{{exceptionText}}</span>
        <el-input
            type="textarea"
            :style="{fontSize: $store.state.floatingWindow.fontSize+'px'}"
            :autosize="{ minRows: 27, maxRows: 14}"
            placeholder="请输入内容"
            autofocus="true"
            v-model="textarea" @input="changeJsonStr">
        </el-input>
        <div  style="margin-top: 30px">
          展示key双引号
          <el-switch
              v-model="state.showDoubleQuotes"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value=true
              :inactive-value=false >
          </el-switch>
          折叠时展示长度
          <el-switch
              v-model="state.showLength"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value=true
              :inactive-value=false
          >
          </el-switch>
          展示行计数
          <el-switch
              v-model="state.showLineNumber"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value=true
              :inactive-value=false >
          </el-switch>

          展示虚线
          <el-switch
              v-model="state.showLine"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value=true
              :inactive-value=false >
          </el-switch>


          <div class="json-container">
            <vue-json-pretty
                :style="{fontSize: $store.state.floatingWindow.prettySize+'px'}"
                :data="resData()"
                :show-double-quotes="state.showDoubleQuotes"
                :show-length="state.showLength"
                :show-line="state.showLine"
                :show-line-number="state.showLineNumber"
                :collapsed-on-click-brackets="state.collapsedOnClickBrackets"
                :show-select-controller="state.showSelectController"
                :show-icon="state.showIcon"
            />
          </div>
          <div>
            <a href="https://beian.miit.gov.cn/" target="_blank">ICP备案网站信息: 豫ICP备2022026995号-1</a>
          </div>

        </div>
      </el-main>

    </el-container>

  </div>
</template>


<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name:"jsonFormatting",
  components: {VueJsonPretty},
  data() {
    return {
      state:{
        //是否展示key双引号
        showDoubleQuotes:true,
        //折叠时展示长度
        showLength:true,
        //展示行计数
        showLineNumber:true,
        //支持点击括号折叠
        collapsedOnClickBrackets:true,
        //展示选择器
        showSelectController:true,
        //展示图标
        showIcon:true,
        //展示标识线
        showLine:true,
      },

      textarea: '',
      exceptionText:"错误信息",
      isException:false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {


    resData(){
      if (this.textarea==null||this.textarea==""){
        return null;
      }
      let json
      try{
        json = JSON.parse(this.textarea);
      }catch (e){
        console.log(this.textarea,"可能不是json")
        return null
      }
      return json;
    },

    init() {
      this.textarea = this.$store.state.jsonStr;
    },


    changeJsonStr(inputStr) {
      this.$store.state.jsonStr = inputStr;
    },


    clear() {
      this.textarea = '';
      this.$store.state.jsonStr = '';
    },


    format() {
      try{
        if (this.textarea==null||this.textarea===''||this.textarea==undefined){
          this.errorHint("请输入内容")
          return;
        }
        this.isException = false;
        const parsedJson = JSON.parse(this.textarea)
        this.textarea = JSON.stringify(parsedJson, null, 2)
      }catch (e){
        this.isException = true;
        this.errorHint(e.stack.replace(/at .*$/gm, '').trim());
      }
      this.$store.state.jsonStr = this.textarea;
    },

    //错误提示
    errorHint(message){
      this.isException = true;
      this.exceptionText = "错误信息:"+message;
    },


    compression() {
      this.textarea = this.textarea.replaceAll("\r", "")
          .replaceAll("\n", "")
          .replaceAll("\t", "")
          .replaceAll(" ", "");
      this.$store.state.jsonStr = this.textarea;
    },


    escape() {
      this.textarea = this.textarea.replaceAll("\"", "\\\"");
      this.$store.state.jsonStr = this.textarea;
    },


    clearEscape() {
      this.textarea = this.textarea
          .replaceAll("\\", "")
          .replaceAll("\"{", "{")
          .replaceAll("\"[", "[")
          .replaceAll("}\"", "}")
          .replaceAll("]\"", "]")
      ;
      this.$store.state.jsonStr = this.textarea;
    },


    decodeUnicode() {
      this.textarea = unescape(this.textarea.replace(/\\u/gi, '%u'))
      this.$store.state.jsonStr = this.textarea;
    },


    encodeUnicode() {
      this.compression();
      let str = this.textarea;
      let res = [];
      for (let i = 0; i < str.length; i++) {
        res[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
      }
      this.textarea = "\\u" + res.join("\\u");
      this.$store.state.jsonStr = this.textarea;
    },


    urlEncoder() {
      this.textarea = encodeURI(this.textarea);
      this.$store.state.jsonStr = this.textarea;
    },


    urlDecoder() {
      this.textarea = decodeURI(this.textarea);
      this.decodeUnicode();
      this.clearEscape();
      this.$store.state.jsonStr = this.textarea;
    }
  },
};
</script>
<style scoped>
.json-container .json-key {
  color: #ff5722;
}
.json-container .json-string {
  color: #127a17;
}
.json-container .json-number {
  color: #2196f3;
}
.json-container .vjs-tree{
  font-size: 20px;
}

.json-container {
  margin-top: 20px;
  height: 800px;
}
</style>
