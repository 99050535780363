<template>
  <!-- 悬浮窗 -->
  <div class="floating-container"  :style="{ top: top + 'px', left:left + 'px' }"
       ref="myButton" >
    <div v-show="showFloating"  :style="{ width:width+'px' ,height: height+'px' }" class="floating-container-div" ref="myButtonDev">
      <el-row :gutter="12">
        <el-col>

          <div   style="height: 30px;background-color: #C89EC4;
           border-radius: 20px 20px 0 0; display: flex;
  align-items: center;">

            <el-col :span="20" >
              <div @mousedown="handleMouseDown" style="padding-left: 9%">
                此处拖动
              </div>

            </el-col>
            <el-col :span="1">

              <span style="align-items: end;"  class="el-icon-minus" @click="toggleFloating">

              </span>

<!--              <el-button style="align-items: end" @click="toggleFloating" size="mini" round>隐藏</el-button>-->
            </el-col>





          </div>




        </el-col>
<!--        <el-col-->
<!--            style="margin-top: 5px;" align="right">-->
<!--          ↑↑↑点上面拖动↑↑↑-->
<!--          <el-button @click="toggleFloating" size="mini" round>隐藏</el-button>-->
<!--        </el-col>-->
      </el-row>


      <el-main style="height: 100%;  margin: 0;
  padding: 0;">
      <el-card shadow="hover">
        悬浮窗数据是否缓存:<el-switch
          v-model="$store.state.floatingWindow.dataCache"
          active-color="#13ce66"
          @click.native="isDataCache()"
          inactive-color="#ff4949">
      </el-switch>
      </el-card>

      <el-card shadow="hover">
        JSON输入框内文字大小:
        <div>
          <el-button type="primary" @click="minusFontSize()" size="mini">-</el-button>&nbsp;
          <el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="$store.state.floatingWindow.fontSize" style="width: 50px;height: 20px!important;" placeholder="请输入内容"></el-input>px
          <el-button type="primary" size="mini" @click="addFontSize()" >+</el-button>
        </div>
      </el-card>

        <el-card shadow="hover">
          JSONPretty文字大小:
          <div>
            <el-button type="primary" @click="minusPrettyFontSize()" size="mini">-</el-button>&nbsp;
            <el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="$store.state.floatingWindow.prettySize" style="width: 50px;height: 20px!important;" placeholder="请输入内容"></el-input>px
            <el-button type="primary" size="mini" @click="addPrettyFontSize()" >+</el-button>
          </div>
        </el-card>


        <el-card shadow="hover">
          JAVA代码执行文字大小:
          <div>
            <el-button type="primary" @click="minusPrettyFontSize()" size="mini">-</el-button>&nbsp;
            <el-input onkeyup="value=value.replace(/[^\d]/g,'')" v-model="$store.state.floatingWindow.prettySize" style="width: 50px;height: 20px!important;" placeholder="请输入内容"></el-input>px
            <el-button type="primary" size="mini" @click="addPrettyFontSize()" >+</el-button>
          </div>
        </el-card>


        <el-card shadow="hover">
          <el-button type="danger" size="medium" @click="resetConfig()" >重置悬浮窗配置</el-button>
        </el-card>


      </el-main>


      <div   style="height: 30px;background-color: #C89EC4;
           border-radius: 0 0 20px 20px; display: flex;
  align-items: center;">
      </div>

    </div>
    <button @mousedown="handleMouseDown" v-show="!showFloating" class="floating_window"  @click="toggleFloating" ></button>
  </div>

</template>

<script>
export default {
  data(){
    return{



      showFloating: false, // 控制悬浮窗显示/隐藏
      top: 0, // 悬浮窗容器的top位置
      left: 0, // 悬浮窗容器的left位置
      startX: 0, // 鼠标按下时的X坐标
      startY: 0, // 鼠标按下时的Y坐标
      bottom: 200,//往上偏移
      right: 180,//往右偏移
      isDragging: false, // 是否正在拖动悬浮窗
      isDrag:false,//刚拖动完毕
      width: 280,//大悬浮窗宽
      height: 400,//大悬浮窗高
      fits: ["fill", "contain", "cover", "none", "scale-down"]
    };
  },
  mounted() {
    const pageH = window.innerHeight;
    const pageW = window.innerWidth;
    this.top =pageH - this.bottom
    this.left = pageW - this.right
    // 监听鼠标移动事件
    document.addEventListener('mousemove', this.handleMouseMove)
    // 监听鼠标松开事件
    document.addEventListener('mouseup', this.handleMouseUp)
  },

  created(){
    //初始化
    this.initializeData()

  },

  methods: {

    //初始化悬浮窗数据
    initializeData(){
      //判断是否有缓存
      let config ;
      try {
        config = JSON.parse(localStorage.getItem("fw-config"))
      } catch (e) {
        localStorage.removeItem("fw-config");
      }
      if (config){
        this.$store.state.floatingWindow = config;
      }else{
        //初始化
        this.$store.state.floatingWindow = Object.assign({}, this.$store.state.floatingWindowInitial);
        this.setConfig()
      }
    },

    //重置按钮
     resetConfig(){
       localStorage.removeItem("fw-config");
       this.$store.state.floatingWindow = Object.assign({}, this.$store.state.floatingWindowInitial);
    },



    //缓存开关
    isDataCache(){
      this.setConfig();
    },


    //加入缓存
    setConfig(){
      if (this.$store.state.floatingWindow.dataCache){
        localStorage.setItem("fw-config",JSON.stringify(this.$store.state.floatingWindow));
      }else{
        localStorage.removeItem("fw-config");
      }
    },




    //输入框文本大小++
    addFontSize(){
      if(this.$store.state.floatingWindow.fontSize<99){
        this.$store.state.floatingWindow.fontSize++;
        this.setConfig()
      }
    },

    //输入框文本大小--
    minusFontSize(){
      if (this.$store.state.floatingWindow.fontSize>1){
        this.$store.state.floatingWindow.fontSize--;
        this.setConfig()
      }
    },

    //jsonPretty文字缩小
    minusPrettyFontSize(){
      if (this.$store.state.floatingWindow.prettySize>1){
        this.$store.state.floatingWindow.prettySize--;
        this.setConfig()
      }
    },

    //jsonPretty文字++
    addPrettyFontSize(){
      if (this.$store.state.floatingWindow.prettySize<99){
        this.$store.state.floatingWindow.prettySize++;
        this.setConfig()
      }

    },





    //按下事件
    handleMouseDown(event) {
      // 鼠标按下时记录起始位置和开始拖动状态
      this.startX = event.clientX
      this.startY = event.clientY
      this.isDragging = true
    },
    handleMouseMove(event) {
      if (this.isDragging) {
        //拖动第一次点击不触发点击事件
        if (!this.showFloating){
          this.isDrag =true;
        }

        // 如果正在拖动悬浮窗，计算新的位置并更新
        const deltaX = event.clientX - this.startX
        const deltaY = event.clientY - this.startY
        //获取浏览器最大X和Y
        const pageWidth = document.documentElement.clientWidth;
        const pageHeight = document.documentElement.clientHeight;
        //获取悬浮窗最大
        const floatingWindow = document.querySelector('.floating-container');
        const floatingWindowWidth = floatingWindow.offsetWidth;
        const floatingWindowHeight = floatingWindow.offsetHeight;
        //让悬浮窗不会移动到窗口外
        if (this.top + deltaY > 0 && this.top + deltaY < pageHeight - floatingWindowHeight) {
          this.top += deltaY
          this.startY = event.clientY
        }
        if (this.left + deltaX > 0 && this.left + deltaX < pageWidth - floatingWindowWidth) {
          this.left += deltaX
          // 更新起始位置
          this.startX = event.clientX
        }
      }
    },
    handleMouseUp() {
      // 鼠标松开时结束拖动状态
      this.isDragging = false
    },

    //显示隐藏
    toggleFloating() {
      this.isDrag?this.isDrag=false:this.showFloating = !this.showFloating;
      //打开时判断大窗口是否越界
      //获取浏览器最大X和Y
      const pageWidth = document.documentElement.clientWidth;
      const pageHeight = document.documentElement.clientHeight;
      if (this.top+this.height  > pageHeight && this.showFloating) {
        this.top = pageHeight - this.height-100;
      }
      if (this.left+this.width  >  pageWidth && this.showFloating) {
        // 更新起始位置
        this.left = pageWidth - this.width-100;
      }
    }
  }

}
</script>
<style lang="scss" scoped>

/*悬浮窗开关按钮*/
.floating_window{
  background-image: url('../../image/icons/baoerye.png');
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  padding: 40px 30px;
  border-radius: 5px;
  cursor: pointer;
}

//悬浮窗
.floating-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    cursor: move; /* 鼠标指针样式为移动 */
  }

//悬浮窗div
.floating-container-div {
    position: fixed;
    background-color: rgba(247, 244, 244, 0.61);
  }



</style>
