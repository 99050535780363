import request from '@/utils/request'


//post请求示例
export function postTest(data) {
    return request({
        url: '/utilCollection/jsonPath/evaluation',
        method: 'post',
        data:data
    })
}


