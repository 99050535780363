<template>
  <div>
    jsonPath:<el-input v-model="queryParams.jsonPath" placeholder="请输入内容"></el-input>
    originObj:<el-input v-model="queryParams.originObj" placeholder="请输入内容"></el-input>
    <div>回显:{{text.result}}</div>
    <el-button type="primary" @click="gteTest">发送请求</el-button>

  </div>

</template>
<script>
import {postTest} from "@/api/test";

export default {
  data() {
    return {
      queryParams:{
        jsonPath:"$",
        originObj:'{"orderType":0,"orderNo":"DD1613776755934498816"}'
      },

      text: {
        code: 0,
        msg: '',
        result: {}
      },
    };
  },
  methods: {

    gteTest(){
      postTest(this.queryParams).then((response) => {
        this.text=response;
      })
    }
  }
};
</script>
<style scoped>
</style>
