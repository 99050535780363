<template>
  <div>
    <el-container>
      <el-header>
        <el-tabs v-model="index" @tab-click="handleClick">
        <!--标签展示-->
          <el-tab-pane
              v-for="item in editableTabs"
              :label="item.title"
              :name="item.name">
          </el-tab-pane>
        </el-tabs>
      </el-header>
      <!-- 子页面展示-->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

  </div>
</template>
<script>
export default {

  name: "jsonUtil",

  data() {
    return {
      index:"1",
      editableTabs: [{
        title: 'json格式化',
        name: '1',
        route: 'jsonFormatting'
      }, {
        title: 'jsonPath',
        name: '2',
        route: 'jsonPath'
      }],
    };
  },

  created(){
    this.handleClick({index:0})
  },
  methods: {

    handleClick(tab) {
         let routePath = this.editableTabs[tab.index].route
         this.editableTabsValue = this.editableTabs[tab.index].name;
      this.$router.push({name: routePath}).catch(err => err)
    },

  }
};
</script>
<style scoped>
</style>
